<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <h1>{{bookTitle}}</h1>
      <h6>Maximilien Andile</h6>
      <small>Last update: 19th July 2022 - <b-link :to="{name:routeNameChangeLog}">changelog</b-link></small>
      <b-row class="mb-5 mt-3" cols="1" cols-md="2">
        <b-col md="7" >
          <div id="table-of-contents" class="anchor"></div>
          <h2 >Table of contents</h2>
          <hr>
          <TocHome></TocHome>
          <BuyCopyInvite></BuyCopyInvite>
        </b-col>
        <b-col md="5">
          <b-img fluid-grow class="text-center" thumbnail :src="require('@/assets/images/drawings_opti2/main.jpg')" fluid alt="Responsive image"></b-img>
          <b-img fluid-grow class="text-center" thumbnail :src="require('@/assets/images/image-book/practical-go-lessons-book10.jpg')" fluid alt="Responsive image"></b-img>
          <b-button variant="success" :to="{name:routeNameBuy}">Buy the book !</b-button>
        </b-col>

      </b-row>

    </b-container>
    <Footer></Footer>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar";
import Consts from "@/consts";
import Footer from "@/components/Footer";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import TocHome from "@/components/TocHome";

const title = "Home - Practical Go Lessons Book"
const description = "My main objective is to teach you the language in a progressive way. I also tried to clarify and explain some common computer science notions that can be difficult to grasp, especially for newcomers."
export default {
  name: 'Home',
  components: {
    TocHome,
    BuyCopyInvite,
    Footer,
    NavBar
  },
  metaInfo: {
    title: title,
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: description },
      { name: 'robots', content: "index, follow" },
      { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
      { property: 'og:type', content: "website" },
      { property: 'og:title', content: title},
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:site_name', content: 'Practical Go Lessons' },
      { property: 'twitter:card', content: "summary_large_image" },
      { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
    ],
    link : [
      { rel : "canonical", href : window.location.href}
    ]

  },
  data() {
    return {
      routeNameBuy : Consts.ROUTE_NAMES.BUY,
      routeNameChangeLog: Consts.ROUTE_NAMES.CHANGELOG,
      bookTitle : Consts.BOOK_TITLE,
      publicPath: process.env.BASE_URL
    }
  },
  created() {
    window.scrollTo(0,0);
  }

}
</script>
