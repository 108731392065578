import { render, staticRenderFns } from "./TocHome.vue?vue&type=template&id=4b81559e&scoped=true&functional=true&"
import script from "./TocHome.vue?vue&type=script&lang=js&"
export * from "./TocHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4b81559e",
  null
  
)

export default component.exports