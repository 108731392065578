
<template functional>
  <b-list-group>
    <b-list-group-item :to="{name:'Foreword'}" >Foreword</b-list-group-item>

    <b-list-group-item :to="{name:'Chap1ProgrammingAComputer'}">Chap. 1: Programming A Computer</b-list-group-item>

    <b-list-group-item :to="{name:'Chap2TheGoLanguage'}">Chap. 2: The Go Language</b-list-group-item>

    <b-list-group-item :to="{name:'Chap3TheTerminal'}">Chap. 3: The terminal</b-list-group-item>

    <b-list-group-item :to="{name:'Chap4SetupYourDevEnvironment'}">Chap. 4: Setup your dev environment</b-list-group-item>

    <b-list-group-item :to="{name:'Chap5FirstGoApplication'}">Chap. 5: First Go Application</b-list-group-item>

    <b-list-group-item :to="{name:'Chap6BinaryAndDecimal'}">Chap. 6: Binary and Decimal</b-list-group-item>

    <b-list-group-item :to="{name:'Chap7HexadecimalOctalAsciiUtf8UnicodeRunes'}">Chap. 7: Hexadecimal, octal, ASCII, UTF8, Unicode, Runes</b-list-group-item>

    <b-list-group-item :to="{name:'Chap8VariablesConstantsAndBasicTypes'}">Chap. 8: Variables, constants and basic types</b-list-group-item>

    <b-list-group-item :to="{name:'Chap9ControlStatements'}">Chap. 9: Control Statements</b-list-group-item>

    <b-list-group-item :to="{name:'Chap10Functions'}">Chap. 10: Functions</b-list-group-item>

    <b-list-group-item :to="{name:'Chap11PackagesAndImports'}">Chap. 11: Packages and imports</b-list-group-item>

    <b-list-group-item :to="{name:'Chap12PackageInitialization'}">Chap. 12: Package Initialization</b-list-group-item>

    <b-list-group-item :to="{name:'Chap13Types'}">Chap. 13: Types</b-list-group-item>

    <b-list-group-item :to="{name:'Chap14Methods'}">Chap. 14: Methods</b-list-group-item>

    <b-list-group-item :to="{name:'Chap15PointerType'}">Chap. 15: Pointer type</b-list-group-item>

    <b-list-group-item :to="{name:'Chap16Interfaces'}">Chap. 16: Interfaces</b-list-group-item>

    <b-list-group-item :to="{name:'Chap17GoModules'}">Chap. 17: Go modules</b-list-group-item>

    <b-list-group-item :to="{name:'Chap18GoModuleProxies'}">Chap. 18: Go Module Proxies</b-list-group-item>

    <b-list-group-item :to="{name:'Chap19UnitTests'}">Chap. 19: Unit Tests</b-list-group-item>

    <b-list-group-item :to="{name:'Chap20Arrays'}">Chap. 20: Arrays</b-list-group-item>

    <b-list-group-item :to="{name:'Chap21Slices'}">Chap. 21: Slices</b-list-group-item>

    <b-list-group-item :to="{name:'Chap22Maps'}">Chap. 22: Maps</b-list-group-item>

    <b-list-group-item :to="{name:'Chap23Errors'}">Chap. 23: Errors</b-list-group-item>

    <b-list-group-item :to="{name:'Chap24AnonymousFunctionsAndClosures'}">Chap. 24: Anonymous functions & closures</b-list-group-item>

    <b-list-group-item :to="{name:'Chap25JsonAndXml'}">Chap. 25: JSON and XML</b-list-group-item>

    <b-list-group-item :to="{name:'Chap26BasicHttpServer'}">Chap. 26: Basic HTTP Server</b-list-group-item>

    <b-list-group-item :to="{name:'Chap27EnumIotaAndBitmask'}">Chap. 27: Enum, Iota & Bitmask</b-list-group-item>

    <b-list-group-item :to="{name:'Chap28DatesAndTime'}">Chap. 28: Dates and time</b-list-group-item>

    <b-list-group-item :to="{name:'Chap29DataStorageFilesAndDatabases'}">Chap. 29: Data storage : files and databases</b-list-group-item>

    <b-list-group-item :to="{name:'Chap30Concurrency'}">Chap. 30: Concurrency</b-list-group-item>

    <b-list-group-item :to="{name:'Chap31Logging'}">Chap. 31: Logging</b-list-group-item>

    <b-list-group-item :to="{name:'Chap32Templates'}">Chap. 32: Templates</b-list-group-item>

    <b-list-group-item :to="{name:'Chap33ApplicationConfiguration'}">Chap. 33: Application Configuration</b-list-group-item>

    <b-list-group-item :to="{name:'Chap34Benchmarks'}">Chap. 34: Benchmarks</b-list-group-item>

    <b-list-group-item :to="{name:'Chap35BuildAnHttpClient'}">Chap. 35: Build an HTTP Client</b-list-group-item>

    <b-list-group-item :to="{name:'Chap36ProgramProfiling'}">Chap. 36: Program Profiling</b-list-group-item>

    <b-list-group-item :to="{name:'Chap37Context'}">Chap. 37: Context</b-list-group-item>

    <b-list-group-item :to="{name:'Chap38Generics'}">Chap. 38: Generics</b-list-group-item>

    <b-list-group-item :to="{name:'Chap39AnObjectOrientedProgrammingLanguage'}">Chap. 39: An object oriented programming language ?</b-list-group-item>

    <b-list-group-item :to="{name:'Chap40UpgradingOrDowngradingGo'}">Chap. 40: Upgrading or Downgrading Go</b-list-group-item>

    <b-list-group-item :to="{name:'Chap41DesignRecommendations'}">Chap. 41: Design Recommendations</b-list-group-item>

    <b-list-group-item :to="{name:'Chap42Cheatsheet'}">Chap. 42: Cheatsheet</b-list-group-item>

  </b-list-group>
</template>

<script>
export default {
name: "TocHome"
}
</script>

<style scoped>

</style>
