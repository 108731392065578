<template>
  <b-alert :show="true" dismissible>
    The <strong>paper</strong> and the <strong>digital</strong> edition of this book are available <b-link :to="{name:routNameHardCopy}">here.</b-link>
    <br>I also filmed a <b-link :to="{name:this.routes.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}"><strong>video course</strong></b-link> to build a real world project with Go.
  </b-alert>
</template>

<script>
import Consts from "@/consts";

export default {
  name: "BuyCopyInvite",
  data() {
    return {
      routes : Consts.ROUTE_NAMES,
      routNameHardCopy: Consts.ROUTE_NAMES.BUY,
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
    }
  },
}
</script>

<style scoped>

</style>